<template>
  <RikazSearchField
    :value="name"
    class="ltr:mr-3.5 rtl:ml-3.5"
    :placeholder="placeholder"
    :input-class="inputClass"
    :search-size-icon="20"
    prefix
    close-class="text-mevcut-light w-5 h-5 cursor-pointer"
    @search-term="filterName"
  />
</template>

<script setup lang="ts">
import { useCategoriesCrumbsStore } from '~/store/categories_crumbs'
const props = withDefaults(defineProps<{ inputClass?: string, withOutQueryNavigation?: boolean }>(), {
  inputClass: 'w-full', withOutQueryNavigation: false
})
const { t } = useI18n()
const emit = defineEmits(['go-material-search'])
const route = useRoute()
const name = ref(route.query.name as string)
const placeholder = computed(() => {
  const selectedCategorty = useCategoriesCrumbsStore().selectedCategory
  if (selectedCategorty?.name.trim() && !props.withOutQueryNavigation) {
    return t('search_with') + ' ' + selectedCategorty?.name
  } else {
    return t('enter_search_text')
  }
})
function filterName(value: string) {
  if (props.withOutQueryNavigation) {
    emit('go-material-search', value)
  } else {
    useQueryNavigation({ name: value })
  }
}
</script>
