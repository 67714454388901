<template>
  <div>
    <div v-if="categories?.length" class="min-h-[100px]">
      <div v-if="keyAncestorsCategory">
        <slot name="Categories" :categories="categories">
          <StoresCategorySlider :categories="categories" />
        </slot>
      </div>
    </div>

    <div v-if="supportFilter" class="sm:mt-2 mevcut-container">
      <MaterialsStoreSearchFilterSort
        :key="keyAncestorsCategory"
        :class="styleFilter"
        :categories="categories"
        :store-type-id="storeTypeId"
        :fetch-params="fetchParams"
        @update="updateCategoryAncestorsKey()"
      />

      <MaterialsBreadCrumbsCategories
        v-if="!useCategoryStyle()"
        class="mx-auto max-w-max"
        @update="updateCategoryAncestorsKey()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'
import type { Category } from '~~/composables/useMenuModel'
import { useCategoriesCrumbsStore } from '~/store/categories_crumbs'
const { categories: categoriesStore } = useDomainCategoriesStore()
const { fetchCategoryQueryWithAncestors } = useCategoriesCrumbsStore()
const route = useRoute()
const props = withDefaults(
  defineProps<{
    fetchParams?: object
    storeTypeId?: number | string
    categories?: Category[]
    supportFilter: boolean
    styleFilter?: string
    useCategoriesDomain?: boolean
  }>(),
  {
    categories: undefined,
    storeTypeId: undefined,
    useCategoriesDomain: false,
    styleFilter: 'sm:mx-auto max-w-lg',
    fetchParams: () => ({})
  }
)
const keyAncestorsCategory = ref<any>(null)
const categories = computed(() => {
  return props.categories || (props.useCategoriesDomain ? categoriesStore : [])
})

await fetchCategoryQueryWithAncestors(route.params.id)
  .finally(updateCategoryAncestorsKey)

function updateCategoryAncestorsKey() {
  keyAncestorsCategory.value = undefined

  setTimeout(() => {
    keyAncestorsCategory.value = Math.random()
  }, 200)
}
</script>
