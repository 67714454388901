<template>
  <div class="flex flex-wrap justify-center text-xs sm:text-sm my-2">
    <div
      v-for="(r, index) in crumbs"
      :key="`bread-crumbs-route-${index}`"
      class="flex items-end mb-1"
    >
      <div
        class="hover:underline hover:cursor-pointer text-text_color"
        :class="{ 'text-opacity-50': categoryId != r.id.toString() }"
        @click="selectCategory(r.id)"
      >
        <div class="leading-none">{{ r.name }}</div>
      </div>

      <v-icon v-if="index < crumbs.length - 1" icon="fa: fa-solid fa-chevron-up" :size="13" class="mx-1 ltr:rotate-90 rtl:-rotate-90 opacity-50" />
    </div>
  </div>
</template>

<script setup lang="ts">

import { useCategoriesCrumbsStore } from '~/store/categories_crumbs'

const store = useCategoriesCrumbsStore()
const route = useRoute()

const categoryId = computed(() => {
  return route.query.category_id as string | undefined
})

const crumbs = computed(() => {
  return store.path
})

const emit = defineEmits(['update'])

function selectCategory(id: number) {
  if (id.toString() !== categoryId.value) {
    useQueryNavigation({ category_id: id })
    emit('update')
  }
}

onUnmounted(() => {
  store.clear()
})
</script>
