<template>
  <div
    v-for="definition in materialDefinitions"
    :key="`materialDefinition-${definition.id}`"
  >
    <div v-if="definition?.properties?.length">
      <div
        v-if="(materialDefinitions?.length || 0) > 1&&getPropertiesVisible(definition)?.length"
        class="mt-2 text-center font-bold"
      >
        {{ definition.name }}
      </div>
      <div
        v-for="property in definition?.properties"
        :key="`properties-${property.id}`"
      >
        <div v-if="property.visible_in_filters">
          <div class="flex font-medium mb-2">
            <div class="text-nav_color">|</div>
            <div class="mx-2">{{ property.label }}</div>
          </div>
          <div class="flex flex-wrap mt-3 bg-bg_alt_color rounded-lg mx-2">
            <button
              v-for="option in property.options"
              :key="`store-filter-order-${option.id}`"
              class="text-sm mx-1"
              @click="updateProperty(definition, property, option.id)"
            >
              <div
                v-if="property.type == 'Multi value'"
                class="px-3 py-1 rounded-xl"
                :class="{ 'active bg': isActiveOption(property.id, option.id) }"
              >
                {{ option.value }}
              </div>
              <div
                v-if="property.type == 'Multi color'"
                class="px-3 py-1 rounded-xl"
              >
                <div
                  class="w-6 h-6 p-px overflow-hidden cursor-pointer rounded-full"
                  :class="
                    isActiveOption(property.id, option.id)
                      ? 'border-nav_color border-2'
                      : 'border-black border'
                  "
                >
                  <div
                    class="rounded-full h-full w-full"
                    :style="`background-color: ${option.value}`"
                  ></div>
                </div>
              </div>
            </button>
          </div>
          <div class="h-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  MaterialDefinition,
  PropertiesLookupValuesCustom
} from '~~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    materialDefinitions?: MaterialDefinition[]
  }>(),
  {
    materialDefinitions: undefined
  }
)
function goQueryDynamic() {
  let tempQuery = {} as any
  if (
    !areObjectsEqual(queryDynamicEditable.value, queryDynamicOriginal.value)
  ) {
    tempQuery = { ...queryDynamicEditable.value }
  }
  const tempQueryFiltered = Object.entries(tempQuery).filter(
    ([key]) => tempQuery[key] != null
  )

  if (
    route.query.definition_id != currentDefinitionId &&
    tempQueryFiltered.length
  ) {
    tempQuery.definition_id = currentDefinitionId.value
  }

  return tempQuery
}

defineExpose({
  goQueryDynamic
})
const queryDynamicEditable = ref<{}>({}) as any
const queryDynamicOriginal = ref<{}>({}) as any
const route = useRoute()
const currentDefinitionId = ref(queryDynamicEditable.value?.definition_id)

function getPropertiesVisible(materialDefinition: MaterialDefinition) {
  return materialDefinition.properties?.filter((el) => el.visible_in_filters === 1)
}
function updateProperty(
  definition: MaterialDefinition,
  property: PropertiesLookupValuesCustom,
  optionId: any
) {
  if (currentDefinitionId.value != definition.id) {
    const propertiesOldDefinition = props.materialDefinitions?.find(
      (el) => el.id == currentDefinitionId.value
    )?.properties
    const keysRemove = propertiesOldDefinition?.reduce((r: string[], a) => {
      return [...r, `DYNAMIC_FIELD_${a.id}`]
    }, [])
    keysRemove?.forEach((key) => {
      queryDynamicEditable.value[key] = undefined
    })
  }
  currentDefinitionId.value = definition.id
  if (queryDynamicEditable.value[`DYNAMIC_FIELD_${property.id}`] == optionId) {
    queryDynamicEditable.value[`DYNAMIC_FIELD_${property.id}`] = undefined
    queryDynamicEditable.value.definition_id = undefined
  } else {
    queryDynamicEditable.value[`DYNAMIC_FIELD_${property.id}`] = optionId
  }
}
function isActiveOption(idAttribute: any, idOption: any) {
  return queryDynamicEditable.value[`DYNAMIC_FIELD_${idAttribute}`] == idOption
}
onMounted(() => {
  queryDynamicEditable.value = Object.fromEntries(
    Object.entries(route.query).filter(([key]) => key.includes('DYNAMIC_FIELD'))
  )
  queryDynamicOriginal.value = { ...queryDynamicEditable.value }
  currentDefinitionId.value = route.query.definition_id
})

function areObjectsEqual(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}
</script>
